<template lang="html">
  <div class="btn btn-default tile">
    <div class="tile-icons">
      <div class="tile-icon-container">
        <slot name="icon-left" />
      </div>
      <div class="tile-icon-container">
        <slot name="icon-right" />
      </div>
    </div>
    <div class="tile-abbreviation">
      <slot name="abbreviation" />
    </div>
    <div class="tile-color" :style="{backgroundColor: color || '#3b3b3b'}" />
    <div class="tile-name ellipsis">
      <slot name="name" />
    </div>

    <div class="subtile-name ellipsis">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: String
  }
}
</script>

<style lang="scss" scoped>
.tile {
  width: 160px;
  margin: 5px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
}
.tile-icons {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 10px;

  display: flex;
  justify-content: space-between;

  .tile-icon-container {
    border-radius: 10px;
    width: 20px;
    height: 20px;
    margin: 0px -5px;
  }
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}
.tile-abbreviation {
  font-size: 2em;
  margin: -8px;
  width: 100%;

  &.missing {
    color: rgba(70, 70, 70, 0.3);
  }
}
.tile-color {
  width: 60px;
  height: 2px;
  margin-bottom: -3px;
  margin-top: 2px;
}
.tile-name {
  font-size: 1.1em;
}
.subtile-name {
  font-size: 0.9em;
}
</style>
