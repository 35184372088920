<template lang="html">
  <div class="collapsible-section" :class="{expanded: show}">
    <div class="collapsible-section-header" :class="{expanded: show}" @click="toggle">
      <h4 class="header-text">
        <slot name="header">
          {{ header }}
        </slot>
      </h4>
      <div class="header-control">
        <small class="hint">
          <transition name="fade" mode="out-in">
            <div v-if="show" :key="true">
              <slot name="hint-hide" />
            </div>
            <div v-else :key="false">
              <slot name="hint-show" />
            </div>
          </transition>
        </small>
        <div class="header-icon" :class="{expanded: show}">
          <i class="fa fa-caret-down" />
        </div>
      </div>
    </div>
    <CollapseTransition @shown="$emit('shown')">
      <div v-show="show" class="collapsible-section-content">
        <div class="default-slot-content">
          <slot />
        </div>
      </div>
    </CollapseTransition>
  </div>
</template>

<script>
import CollapseTransition from '@components/transitions/CollapseTransition'

export default {
  components: {
    CollapseTransition
  },
  props: {
    header: {},
    value: {
      type: Boolean,
      default: null
    }
  },
  data () {
    return {
      internalShow: false
    }
  },
  computed: {
    show () {
      return this.value === null ? this.internalShow : this.value
    }
  },
  methods: {
    toggle () {
      if (this.value === null) {
        this.internalShow = !this.internalShow
      } else {
        this.$emit('input', !this.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.collapsible-section {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  flex-shrink: 0;

  margin: 4px 0;

  .hint {
    color: #bbb;
  }

  &.expanded {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

    .hint {
      color: gray;
    }
  }

  .collapsible-section-header {
    display: flex;
    justify-content: space-between;

    border: 1px solid transparent;
    padding: 5px;
    padding-left: 0;
    margin-left: -1px;

    &:hover {
      border-top-color: lightgray;
      border-bottom-color: lightgray;
    }

    transition: border-color 0.5s ease-in-out, background-color 0.5s ease-in-out, padding 0.5s ease-in-out;

    &.expanded {
      margin-left: 0;
      padding-left: 5px;
      background-color: #e0e0e0;

      border-color: lightgray;

      .header-text {
        margin-left: 5px;
      }
    }

    cursor: pointer;

    .header-text {
      margin: 0;
      transition: margin 0.5s ease-in-out;
    }

    .header-control {
      display: flex;
      align-items: center;

      .header-icon {
        transition: transform 0.5s ease-in-out;

        transform: rotate(90deg);

        .fa {
          margin: 0 5px;
        }
      }
    }

    &.expanded .header-icon {
      transform: rotate(0);
    }
  }

  .collapsible-section-content {
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;

    .default-slot-content {
      padding: 5px;
    }
  }
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.25s;
}
</style>
