<template lang="html">
  <div :class="$style.settings">
    <Landsat />

    <RedBorderPreview
      v-model="buffer"
      :enabled.sync="useRedBorder"
      :original-border="borderGeometry"
      @error="$emit('error', $event)"
    />

    <CloudCoverageThreshold />
  </div>
</template>

<script>
import {mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'

import RedBorderPreview from '@components/geometry/RedBorderPreview'

import CloudCoverageThreshold from './advanced-settings/CloudCoverageThreshold'
import Landsat from './advanced-settings/Landsat'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    Landsat,
    RedBorderPreview,
    CloudCoverageThreshold
  },
  mixins: [
    PermissionMixin
  ],
  computed: {
    ...mapFormFields('fieldRecordSystem/automaticZoneMaps', [
      'buffer',
      'useRedBorder'
    ]),
    ...mapState('fieldRecordSystem/automaticZoneMaps', [
      'borderGeometry'
    ])
  }
}
</script>

<style lang="scss" module>
.settings {
  display: flex;
  margin-bottom: 5px;
}
</style>
