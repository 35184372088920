<template lang="html">
  <div class="landsat-checkbox">
    <BsCheckbox
      v-model="automaticModeUseLandsat"
      :description="$i18n.translate('automaticZoneMaps.infos.useLandsatDescription')"
    >
      <template #label>
        <IxRes>automaticZoneMaps.checkboxes.useLandsat</IxRes>
      </template>
    </BsCheckbox>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import BsCheckbox from '@components/bootstrap/BsCheckbox'

export default {
  components: {
    BsCheckbox
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/automaticZoneMaps', [
      'showModeDetails',
      'automaticModeUseLandsat',
      'cloudCoverageThreshold'
    ])
  }
}
</script>

<style lang="scss">
.landsat-checkbox, .red-border {
  border-right: 1px solid #E0E0E0;
  padding-right: 5px;
  margin-right: 5px;
}
</style>
