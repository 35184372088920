<template lang="html">
  <div class="selector">
    <Search
      v-model="searchString" :data="sortedCropUsages"
      :keys="searchKeys"
      :threshold="0.3" @result="searchResult = $event"
    />

    <div ref="scrollContainer" class="grid">
      <template v-if="favorites.length">
        <h3>
          <IxRes>Common.SR_Common.Favorites</IxRes>
        </h3>

        <transition-group name="list-complete" tag="p" class="section">
          <div v-for="cropUsage in favorites" :key="cropUsage.id" class="list-complete-item">
            <FrsCropUsageSelectionGridTile
              :crop-usage="cropUsage" :favorited="favoriteLookup[cropUsage.id]"
              @click.native="$emit('input', cropUsage.id)"
              @toggleFavorite="onToggleFavorite(cropUsage.id)"
            />
          </div>
        </transition-group>
        <hr style="width: 90%">
      </template>

      <div class="section">
        <FrsCropUsageSelectionGridTile
          v-for="cropUsage in (searchResult || sortedCropUsages)" :key="cropUsage.id"
          :crop-usage="cropUsage"
          :favorited="favoriteLookup[cropUsage.id]" @click.native="$emit('input', cropUsage.id)"
          @toggleFavorite="onToggleFavorite(cropUsage.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import {sortBy} from 'lodash'

import {getCropUsageTranslations} from '@frs/helpers/crops'

import Search from '@components/search/Search'

import FrsCropUsageSelectionGridTile from './FrsCropUsageSelectionGridTile'

export default {
  components: {
    FrsCropUsageSelectionGridTile,
    Search
  },
  props: {
    value: String,
    filter: Function
  },
  data () {
    return {
      searchString: '',
      searchKeys: ['name', 'abbreviation', 'categories'],
      searchResult: null
    }
  },
  computed: {
    ...mapGetters('masterData', [
      'cropUsageArray'
    ]),
    ...mapState('fieldRecordSystem', {
      favoritedIds: state => state.userSettings.favorites.cropUsages
    }),
    searchableCropUsages () {
      const usageArray = this.filter ? this.cropUsageArray.filter(this.filter) : this.cropUsageArray

      return usageArray.filter(cu => !cu.isArchived).map(usage => ({
        ...usage,
        ...getCropUsageTranslations(usage.id)
      }))
    },
    sortedCropUsages () {
      return sortBy(this.searchableCropUsages, ['name'])
    },
    favoriteLookup () {
      const lookup = {}
      for (const id of this.favoritedIds) {
        lookup[id] = true
      }
      return lookup
    },
    favorites () {
      return this.sortedCropUsages.filter(({id}) => this.favoriteLookup[id])
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem', [
      'toggleFavorited'
    ]),
    onToggleFavorite (id) {
      this.toggleFavorited({id, type: 'cropUsages'})
    }
  },
  watch: {
    searchResult () {
      this.$refs.scrollContainer.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./grid-selection";
</style>
