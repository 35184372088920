<template lang="html">
  <div class="cultivation-list">
    <div class="header">
      <IxRes>automaticZoneMaps.headers.harvestYear</IxRes>
    </div>
    <div class="header">
      <IxRes>automaticZoneMaps.headers.cultivation</IxRes>
    </div>
    <div class="header" />

    <template v-for="year in relevantYears">
      <template v-if="cultivationsByYear[year].length">
        <template v-for="cultivation in cultivationsByYear[year]">
          <span :key="`${cultivation.id}-${year}-label`" class="year">{{ $i18n.format(year, 'harvestyearlong') }}</span>
          <span
            :key="`${cultivation.id}-${year}-cropUsage`"
            class="name"
            :class="{'not-supported': !supportedCultivations.includes(cultivation)}"
          >
            <span class="cultivation-item">
              <IxRes>masterData.crops.usage.{{ cultivation.cropUsageId }}_name</IxRes>
              <IxRes v-if="cultivation.underseedMixtureId" :context="{mixture: mixtures[cultivation.underseedMixtureId].name}">frs.cultivation.underseedMixture</IxRes>
            </span>
            <span
              v-if="!supportedCultivations.includes(cultivation)" :title="$i18n.translate('automaticZoneMaps.hints.cropUsageNotSupported')"
              class="not-supported-icon"
            >
              <FontAwesomeIcon :icon="icons.notSupported" />
            </span>
          </span>
          <div :key="`${cultivation.id}-${year}-edit-interaction`" class="edit-interaction">
            <IxButton
              v-if="cultivation.isQuickCultivation"
              large class="button"
              @click="addCultivation(year)"
            >
              <template #icon>
                <FontAwesomeIcon :icon="icons.addCultivation" />
              </template>
            </IxButton>
            <IxButton
              v-if="cultivation.isQuickCultivation"
              large class="button"
              @click="deleteQuickCultivation(year)"
            >
              <template #icon>
                <FontAwesomeIcon :icon="icons.deleteQuickCultivation" />
              </template>
            </IxButton>
          </div>
        </template>
      </template>
      <template v-else>
        <span :key="`${year}-label`" class="year">{{ $i18n.format(year, 'harvestyearlong') }}</span>
        <a :key="`${year}-text`" href="#" @click.prevent="addCultivation(year)">
          <IxRes>automaticZoneMaps.buttons.addCultivation</IxRes>
        </a>
        <div :key="`${year}-add`" class="add-interaction">
          <IxButton large class="button" @click="addCultivation(year)">
            <template #icon>
              <FontAwesomeIcon :icon="icons.addCultivation" />
            </template>
          </IxButton>
        </div>
      </template>
    </template>
    <QuickCultivationCreationModal
      v-if="showModal"
      v-model="showModal"
      :year="selectedYear"
      name="cropUsagePicker"
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapState} from 'vuex'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faPencilAlt, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons'

import QuickCultivationCreationModal from './QuickCultivationCreationModal'

import IxButton from '@components/IxButton'

export default {
  components: {
    FontAwesomeIcon,
    IxButton,
    QuickCultivationCreationModal
  },
  data () {
    return {
      showModal: null,
      selectedYear: null
    }
  },
  computed: {
    ...mapState('fieldRecordSystem', {
      mixtures: state => state.catchCropMixtures.data.selection.orgUnit
    }),
    ...mapGetters('fieldRecordSystem/automaticZoneMaps', [
      'cultivationsByYear',
      'supportedCultivations',
      'relevantYears'
    ]),
    icons () {
      return {
        addCultivation: faPencilAlt,
        notSupported: faExclamationTriangle,
        deleteQuickCultivation: faTrashAlt
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/automaticZoneMaps', [
      'deleteQuickCultivation'
    ]),
    addCultivation (year) {
      this.selectedYear = year
      this.showModal = true
    },
    deleteCultivation (year) {
      this.deleteQuickCultivation(year)
    }
  }
}
</script>

<style lang="scss" scoped>
.cultivation-list {
  align-self: flex-start;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 1em;
  align-items: baseline;
  margin-bottom: 1.5em;
  margin-right: 1.5em;

  .cultivation-item {
    display: flex;
    flex-direction: column;
  }

  .header {
    font-weight: bold;
  }

  .year {
    grid-column-start: 1;
  }

  .name {
    display: flex;
  }

  .add-interaction, .edit-interaction {
    display: flex;
    align-items: center;

    .button {
      padding: 6px;
      margin-left: 0.5em;
    }
  }

  .not-supported {
    color: grey;
  }

  .not-supported-icon {
    margin-left: 0.5em;
    cursor: help;
  }
}
</style>
