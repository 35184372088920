<template lang="html">
  <NumericInput
    v-model="cloudCoverageThreshold"
    name="cloudCoverageThreshold"
    percent
    :rule="x => x >= 0 && x <= 1"
    class="input-cloud-coverage-threshold"
    :description="$i18n.translate('automaticZoneMaps.inputs.description.cloudCoverageThreshold')"
  >
    <template #label>
      <IxRes>automaticZoneMaps.inputs.label.cloudCoverageThreshold</IxRes>
    </template>
  </NumericInput>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'

import NumericInput from '@components/forms/NumericInput'

export default {
  components: {
    NumericInput
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/automaticZoneMaps', [
      'cloudCoverageThreshold'
    ])
  }
}
</script>

<style lang="scss">
.input-cloud-coverage-threshold {
  ::v-deep .input-field {
    width: 100px;
  }
}
</style>
