<template lang="html">
  <div class="red-border">
    <div class="red-border-inputs">
      <div class="red-border-checkbox">
        <BsCheckbox
          v-model="enabledModel"
        >
          <template #label>
            <IxRes>Common.SR_Common.checkbox.label.useRedBorder</IxRes>
          </template>
          <template #description>
            <IxRes>Common.SR_Common.infos.useRedBorder</IxRes>
          </template>
        </BsCheckbox>
      </div>
      <NumericInput
        v-model="valueModel"
        :disabled="!enabled"
        :rule="x => x > 0"
        integer unit="m"
        name="buffer"
      >
        <template #label>
          <IxRes>Common.SR_Common.inputs.label.redBorderBuffer</IxRes>
        </template>
      </NumericInput>
    </div>

    <HelpBox v-if="bufferedFeature === undefined" type="danger">
      <IxRes>Common.SR_Common.hints.wrongBufferSize</IxRes>
    </HelpBox>

    <IxVectorLayer :features="previewFeatures" :vector-style="style" :z-index="3" />
  </div>
</template>

<script>
import buffer from '@turf/buffer'

import {fieldBorder} from '@frs/map-styles'

import BsCheckbox from '@components/bootstrap/BsCheckbox'
import NumericInput from '@components/forms/NumericInput'
import HelpBox from '@components/help/HelpBox'
import IxVectorLayer from '@components/map/IxVectorLayer'

export default {
  components: {
    IxVectorLayer,
    BsCheckbox,
    NumericInput,
    HelpBox
  },
  props: {
    originalBorder: Object,
    value: Number,
    enabled: Boolean
  },
  computed: {
    valueModel: {
      get () { return this.value },
      set (value) { this.$emit('input', value) }
    },
    enabledModel: {
      get () { return this.enabled },
      set (enabled) { this.$emit('update:enabled', enabled) }
    },
    bufferedFeature () {
      if (!this.originalBorder || !this.enabled || !this.value || this.value <= 0) {
        return null
      }

      return buffer(this.originalBorder, this.value * (-1.0), 'meters')
    },
    previewFeatures (state, getters, rootState, rootGetters) {
      return this.bufferedFeature ? [this.bufferedFeature] : []
    },
    style () {
      return fieldBorder
    }
  },
  watch: {
    bufferedFeature: {
      immediate: true,
      handler (feature) {
        this.$emit('error', feature === undefined)
      }
    }
  }
}
</script>

<style lang="scss">
.red-border {
  display: flex;
  flex-direction: column;
  margin-right: 5px;

  .red-border-inputs {
    display: flex;

    ::v-deep .input-field {
      width: 100px;
    }
    margin-bottom: 10px;
  }
}

.red-border-checkbox {
  margin-right: 5px;
}
</style>
