<template lang="html">
  <div class="help-box">
    <HelpBox v-if="termsUrl && !paymentData.length">
      <BsCheckbox v-model="modelProxy" inline>
        <IxRes>frs.maps.payment.terms</IxRes>
        <a :href="termsUrl" target="_blank" @click.stop>
          (<IxRes>frs.termsOfService</IxRes>)
        </a>
      </BsCheckbox>
    </HelpBox>
    <HelpBox v-if="paymentData.length" type="warning">
      <CollapsibleSection>
        <template #header class="header">
          <IxRes>frs.maps.payment.paymentData.missing</IxRes>:
        </template>
        <ul>
          <li v-for="data in paymentData" :key="data">
            <IxRes>frs.company.data.{{ data }}</IxRes>
          </li>
        </ul>
      </CollapsibleSection>
    </HelpBox>
    <HelpBox v-if="!termsUrl" type="warning">
      <IxRes>frs.maps.payment.terms.missing</IxRes>
    </HelpBox>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {modelProxy} from '@helpers/vuex'

import BsCheckbox from '@components/bootstrap/BsCheckbox'
import IxRes from '@components/IxRes'
import HelpBox from '@components/help/HelpBox'
import CollapsibleSection from '@components/CollapsibleSection'

import {config} from 'src/js/infrastructure'
export default {
  components: {
    IxRes,
    HelpBox,
    BsCheckbox,
    CollapsibleSection
  },
  props: {
    value: Boolean,
    isMzk: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    modelProxy,
    ...mapGetters('fieldRecordSystem', [
      'entityLookup'
    ]),
    ...mapGetters('fieldRecordSystem/companyData', [
      'missingPaymentDataMZK',
      'missingPaymentData'
    ]),
    paymentData () {
      return this.isMzk ? this.missingPaymentDataMZK : this.missingPaymentData
    },
    termsUrl () {
      return config.mapPaymentTermsUrl
    }
  }
}
</script>

<style lang="scss" scoped>
a:hover {
  cursor: pointer;
}

.help-box {
  ::v-deep .alert {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}
::v-deep .header-text {
  font-size: 14px;
}
</style>
