<template lang="html">
  <div class="search">
    <LabelAndMessage>
      <template v-if="$slots.label" #label>
        <slot name="label" />
      </template>
      <SearchBar :value="value" debounced @input="$emit('input', $event)" />
    </LabelAndMessage>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import SearchBar from './SearchBar'

export default {
  components: {
    SearchBar,
    LabelAndMessage
  },
  props: {
    value: {type: String, required: true},
    data: {type: Array, required: true},
    keys: {type: Array, required: true}, // NOTE if this is supplied as an array literal in the template, it may result in an infinite loop due to interactions with the `fuse` computed. For unknown reasons sometimes the `fuse` computed is re-calculated because the literal changes identity, stalling the process.
    threshold: {type: Number, default: 0.6}
  },
  computed: {
    fuse () {
      return new Fuse(this.data, {
        keys: JSON.parse(JSON.stringify(this.keys)),
        threshold: this.threshold
      })
    },
    result () {
      return this.value ? [...this.fuse.search(this.value)] : null
    }
  },
  watch: {
    result (result) {
      this.$emit('result', result)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
