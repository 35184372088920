<template lang="html">
  <TileBase :color="cropUsage.color">
    <template #icon-left>
      <i v-if="crop.isLeguminous" :title="SR_FieldRecordSystem.Leguminous" class="fa fa-leaf leguminous" />
    </template>
    <template #icon-right>
      <TileFavoriteIcon v-bind="{favorited}" @toggle="$emit('toggleFavorite')" />
    </template>
    <template #name>
      <span :title="cropUsage.name">
        <IxRes>masterData.crops.usage.{{ cropUsage.id }}_name</IxRes>
      </span>
    </template>
    <template #abbreviation>
      <span :class="{missing: !cropUsage.abbreviation}">
        <IxRes v-if="cropUsage.abbreviation">masterData.crops.usage.{{ cropUsage.id }}_abbreviation</IxRes>
        <IxRes v-else :process="x => x.substring(0, 3)">masterData.crops.usage.{{ cropUsage.id }}_name</IxRes>
      </span>
    </template>
  </TileBase>
</template>

<script>
import {mapState} from 'vuex'

import {mapResources} from '@helpers/vuex'

import TileBase from '@components/TileBase'
import TileFavoriteIcon from '@components/TileFavoriteIcon'

export default {
  components: {
    TileBase,
    TileFavoriteIcon
  },
  props: {
    cropUsage: Object,
    favorited: Boolean
  },
  computed: {
    ...mapResources([
      '@frs.SR_FieldRecordSystem'
    ]),
    ...mapState('masterData', [
      'crops'
    ]),
    crop () {
      return this.crops[this.cropUsage.cropId]
    }
  }
}
</script>

<style lang="scss" scoped>
.leguminous {
  color: rgb(0, 176, 23);
}
</style>
