<template lang="html">
  <BsModal v-model="modelProxy" :loading="loading">
    <template #title>
      <IxRes>Areas.FieldRecordSystem.SR_FieldRecordSystem.CropTypeSelectTitle</IxRes>
    </template>

    <FrsCropUsageSelectionGrid :filter="filter" @input="onSelectCropUsage" />
  </BsModal>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import {modelProxy} from '@helpers/vuex'

import BsModal from '@components/bootstrap/BsModal'
import IxRes from '@components/IxRes'

import FrsCropUsageSelectionGrid from '@frs/components/base/FrsCropUsageSelectionGrid'

import StandardRulesMixin from '@components/forms/StandardRulesMixin'

export default {
  components: {
    BsModal,
    IxRes,
    FrsCropUsageSelectionGrid
  },
  mixins: [
    StandardRulesMixin
  ],
  props: {
    value: Boolean,
    year: Number
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    modelProxy,
    ...mapState('fieldRecordSystem/automaticZoneMaps', [
      'mode',
      'supportedCropUsageIdsForAuto'
    ]),
    filter () {
      return cropUsage => {
        return this.mode === 'premium' || this.supportedCropUsageIdsForAuto.includes(cropUsage.id)
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/automaticZoneMaps', [
      'upsertQuickCultivation'
    ]),
    onSelectCropUsage (cropUsageId) {
      this.upsertQuickCultivation({cropUsageId, year: this.year})
      this.modelProxy = false
    }
  }
}
</script>

<style lang="css" scoped>
</style>
