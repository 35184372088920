<template lang="html">
  <transition>
    <i
      v-show="show" class="fa fa-close fa-lg clear-button"
      :style="{right}"
      tabindex="0"
      role="button"
      aria-label="clear"
      @click.stop="$emit('click')"
    />
  </transition>
</template>

<script>
export default {
  props: {
    show: Boolean,
    right: String
  }
}
</script>

<style lang="scss" scoped>
.v-enter, .v-leave-to {
  opacity: 0;
}

.clear-button {
  position: absolute;
  top: 7px;
  // right: 46px;
  z-index: 2;

  color: #808080;
  // background-color: #dfdfdf;

  height: 22px;
  width: 22px;
  border-radius: 11px;

  padding: 4px;

  cursor: pointer;

  transition: opacity 0.15s, color 0.3s, background-color 0.3s;

  &:hover {
    color: darken(#808080, 10%);
    background-color: darken(#dfdfdf, 10%);
  }
}
</style>
