<template lang="html">
  <div :class="$style.button">
    <i
      class="fa fa-star" :title="favorited ? 'Unfavorite' : 'Favorite'"
      :class="{[$style.favorited]: favorited}"
      @click.stop="$emit('toggle')"
    />
  </div>
</template>

<script>
export default {
  props: {
    favorited: Boolean
  }
}
</script>

<style lang="scss" module>
.button {
  cursor: pointer;

  &:hover {
    background-color: #dcdcdc;
  }

  i {
    color: #CCC;
    &:hover {
      color: #DAA520;
    }

    &.favorited {
      color: #DAA520;

      &:hover {
        color: #AAA;
      }
    }
  }
}
</style>
