import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'
const resources = {
  SR_Common: 'Common.SR_Common'
}

function format (str, arr) {
  return str
    ? str.toString().replace(/\{([0-9]+)\}/g, (match, index) => {
      return arr[index]
    })
    : `format string missing, values: ${arr}`
}

function combine (f, messages) {
  f.messages = messages
  return f
}

export default {
  mixins: [makeResourceMixin(resources)],
  methods: {
    minLength (value) {
      return combine(
        x => x.length >= value,
        {
          error: format(this.SR_Common.RuleMinLengthError, [value]),
          description: format(this.SR_Common.RuleMinLengthDescription, [value])
        }
      )
    },
    maxLength (value) {
      return combine(
        x => x.length <= value,
        {
          error: format(this.SR_Common.RuleMaxLengthError, [value]),
          description: format(this.SR_Common.RuleMaxLengthDescription, [value])
        }
      )
    },
    betweenLength (min, max) {
      return combine(
        x => x.length <= max && x.length <= min,
        {
          error: format(this.SR_Common.RuleBetweenLengthError, [min, max]),
          description: format(this.SR_Common.RuleBetweenLengthDescription, [min, max])
        }
      )
    },
    min (value) {
      return combine(
        x => value === null || value === undefined || x >= value,
        {
          error: format(this.SR_Common.RuleMinError, [value]),
          description: format(this.SR_Common.RuleMinDescription, [value])
        }
      )
    },
    max (value) {
      return combine(
        x => value === null || value === undefined || x <= value,
        {
          error: format(this.SR_Common.RuleMaxError, [value]),
          description: format(this.SR_Common.RuleMaxDescription, [value])
        }
      )
    },
    between (min, max) {
      return combine(
        x => (max === null || max === undefined || x <= max) && (min === null || min === undefined || x >= min),
        {
          error: format(this.SR_Common.RuleBetweenError, [min, max]),
          description: format(this.SR_Common.RuleBetweenDescription, [min, max])
        }
      )
    }
  }
}
