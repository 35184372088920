<template lang="html">
  <div class="additional-infos-container">
    <div class="header">
      <IxRes>automaticZoneMaps.premium.headers.additionalInfos</IxRes>
    </div>
    <div>
      <p>
        <IxRes>automaticZoneMaps.premium.headers.additionalInfoList</IxRes>
      </p>
      <ul>
        <li><IxRes>automaticZoneMaps.premium.infoList.info1</IxRes></li>
        <li><IxRes>automaticZoneMaps.premium.infoList.info2</IxRes></li>
        <li><IxRes>automaticZoneMaps.premium.infoList.info3</IxRes></li>
        <li><IxRes>automaticZoneMaps.premium.infoList.info4</IxRes></li>
        <li><IxRes>automaticZoneMaps.premium.infoList.info5</IxRes></li>
      </ul>
    </div>
    <TextInput
      v-model="notes"
      :rows="8"
      no-resize
      multiline
    >
      <template #label>
        <IxRes>automaticZoneMaps.premium.labels.notes</IxRes>
      </template>
    </TextInput>
  </div>
</template>

<script>
import {mapFormFields} from '@helpers/vuex'
import TextInput from '@components/forms/TextInput'

export default {
  components: {
    TextInput
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/automaticZoneMaps', [
      'notes'
    ])
  }
}
</script>

<style lang="scss" scoped>
.additional-infos-container {
  align-self: flex-start;
  display: grid;
  grid-template-columns: auto;
  gap: 1em;
  align-items: baseline;
  margin-bottom: 1.5em;

  .header {
    font-weight: bold;
  }
}
</style>
