<template lang="html">
  <LabelAndMessage :id="id" :label="label" :description="combinedDescription">
    <span class="bs-checkbox" :class="{inline, 'has-error': dirty && required && !checked, 'has-success': dirty && required && checked}">
      <div v-if="!inline" class="checkbox">
        <label>
          <input
            ref="checkbox" v-model="checked"
            type="checkbox"
            :disabled="disabled"
          >
          <slot />
        </label>
      </div>
      <label v-else class="checkbox-inline">
        <input
          ref="checkbox" v-model="checked"
          type="checkbox"
          :disabled="disabled"
        >
        <slot />
      </label>
    </span>
    <slot slot="label" name="label" />
    <slot v-if="$slots.description" slot="description" name="description" />
  </LabelAndMessage>
</template>

<script>
import {isEqual} from 'lodash'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'

let counter = 0

export default {
  components: {
    LabelAndMessage
  },
  model: {
    prop: 'state',
    event: 'input'
  },
  props: {
    value: {},
    state: {},
    inline: Boolean,
    tristate: Boolean,
    disabled: Boolean,
    required: Boolean,
    label: String,
    description: String
  },
  data () {
    return {
      dirty: false,
      id: `bs-checkbox-${counter++}`
    }
  },
  computed: {
    checked: {
      get () {
        return this.state instanceof Array ? this.state.some(x => isEqual(x, this.value)) : this.state !== false
      },
      set (value) {
        this.dirty = true
        if (this.tristate && !value && this.state !== null) {
          value = null
        }
        if (this.tristate && value && this.state === null) {
          value = false
        }
        this.$forceUpdate()

        if (this.state !== undefined && this.value && !this.tristate) {
          const options = this.state.filter(x => !isEqual(x, this.value))
          if (value) {
            options.push(this.value)
          }
          this.$emit('input', options)
        } else {
          this.$emit('input', value)
        }
      }
    },
    combinedDescription () {
      return [this.description].filter(x => x)
    }
  },
  methods: {
    reset () {
      this.$refs.checkbox.indeterminate = this.state === null
    }
  },
  watch: {
    state () {
      this.reset()
    },
    value () {
      this.reset()
    }
  },
  mounted () {
    this.reset()
  }
}
</script>

<style lang="scss" scoped>
.bs-checkbox.inline {
  margin-right: 0.5em;
}
.compact {
  margin-top: 3px;
  margin-bottom: 3px;
}
input {
  cursor: pointer;
}
</style>
