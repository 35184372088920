<template lang="html">
  <BsModal v-model="modelProxy">
    <template #title>
      <IxRes>automaticZoneMaps.headers.cultivationsMissingModal</IxRes>
    </template>

    <div class="content">
      <div v-if="yearsWithoutCultivation.length > 0">
        <p>
          <IxRes>automaticZoneMaps.hints.cultivationsMissing</IxRes>
        </p>

        <IxRes>automaticZoneMaps.headers.yearsWithoutCultivation</IxRes>
        <ul>
          <li v-for="year in yearsWithoutCultivation" :key="year">
            {{ $i18n.format(year, 'harvestyearlong' ) }}
          </li>
        </ul>
      </div>

      <div v-if="quickCultivations.length" class="quick-cultivations-container">
        <div v-if="quickCultivationsWithFieldHistory.length">
          <IxRes>automaticZoneMaps.headers.createQuickCultivations</IxRes>
          <ul>
            <li v-for="cultivation in quickCultivationsWithFieldHistory" :key="cultivation.id">
              <BsCheckbox
                class="checkbox"
                inline
                :state="selectedQuickCultivations"
                :value="cultivation" @input="onInput(cultivation, $event)"
              >
                <template>
                  <span class="year">
                    {{ $i18n.format(cultivation.harvestYears[0], 'harvestyearlong') }}
                    <IxRes>masterData.crops.usage.{{ cultivation.cropUsageId }}_name</IxRes>
                  </span>
                </template>
              </BsCheckbox>
            </li>
            <div class="selection-button">
              <IxButton @click="checkboxSelection">
                <IxRes v-if="allSelected">frs.mapManagement.buttons.deselectAll</IxRes>
                <IxRes v-else>frs.mapManagement.buttons.selectAll</IxRes>
              </IxButton>
            </div>
            <HelpBox v-if="selectedQuickCultivations.length" type="warning">
              <IxRes>automaticZoneMaps.warnings.createQuickCultivations</IxRes>
            </HelpBox>
          </ul>
        </div>
        <div v-if="quickCultivationsWithoutFieldHistory.length">
          <IxRes>automaticZoneMaps.headers.quickCultivationsWithoutFieldHistory</IxRes>
          <ul>
            <li v-for="cultivation in quickCultivationsWithoutFieldHistory" :key="cultivation.id">
              <template>
                <span class="year">
                  {{ $i18n.format(cultivation.harvestYears[0], 'harvestyearlong') }}
                  <IxRes>masterData.crops.usage.{{ cultivation.cropUsageId }}_name</IxRes>
                </span>
              </template>
            </li>
            <HelpBox type="warning">
              <IxRes>automaticZoneMaps.warnings.quickCultivationsWithoutFieldHistory</IxRes>
            </HelpBox>
          </ul>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="footer">
        <IxButton large @click="onConfirm">
          <IxRes>automaticZoneMaps.buttons.createMapAnyways</IxRes>
        </IxButton>
        <IxButton large @click="$emit('cancel')">
          <IxRes>automaticZoneMaps.buttons.backToCultivations</IxRes>
        </IxButton>
      </div>
    </template>
  </BsModal>
</template>

<script>
import {mapState, mapGetters, mapMutations} from 'vuex'

import {modelProxy} from '@helpers/vuex'

import HelpBox from '@components/help/HelpBox'
import BsCheckbox from '@components/bootstrap/BsCheckbox'
import BsModal from '@components/bootstrap/BsModal'
import IxButton from '@components/IxButton'

export default {
  components: {
    BsModal,
    IxButton,
    BsCheckbox,
    HelpBox
  },
  props: {
    value: Boolean
  },
  data () {
    return {
      allSelected: true
    }
  },
  computed: {
    modelProxy,
    ...mapState('fieldRecordSystem/automaticZoneMaps', [
      'selectedQuickCultivations',
      'existingYearsWithFieldHistory'
    ]),
    ...mapGetters('fieldRecordSystem/automaticZoneMaps', [
      'yearsWithoutCultivation',
      'quickCultivations'
    ]),
    quickCultivationsWithFieldHistory () {
      return this.quickCultivations.filter(cult => this.existingYearsWithFieldHistory.includes(cult.harvestYears[0]))
    },
    quickCultivationsWithoutFieldHistory () {
      return this.quickCultivations.filter(cult => !this.existingYearsWithFieldHistory.includes(cult.harvestYears[0]))
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/automaticZoneMaps', [
      'updateSelectedQuickCultivations',
      'quickCultivationsSelection'
    ]),
    onConfirm () {
      this.$emit('confirm')
    },
    onInput (cultivation, cultivations) {
      this.updateSelectedQuickCultivations(cultivations)
    },
    checkboxSelection () {
      this.allSelected = !this.allSelected
      this.quickCultivationsSelection(this.allSelected)
    }
  },
  watch: {
    selectedQuickCultivations (cultivations) {
      if (cultivations.length === this.quickCultivations.length) {
        this.allSelected = true
      } else {
        this.allSelected = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
}
.selection-button {
  margin-top: 10px;
  margin-bottom: 10px;
}
.quick-cultivations-container {
  display: flex;
  flex-direction: column;
}
.footer {
  display: flex;
  justify-content: space-around;
}
</style>
