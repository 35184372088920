<template lang="html">
  <LabelAndMessage
    :id="id" :label="label"
    :required="required" :placement="placement"
    :feedback-classes="feedbackClasses" :description="combinedDescription"
    :message="message"
  >
    <div :class="{'input-group': addon || unit || usePercent || $slots.inputGroup}" class="input-field">
      <div class="input-wrapper" :title="title">
        <input
          :id="id" ref="input"
          :name="name" :readonly="readonly || disabled"
          :disabled="readonly || disabled" type="text"
          class="form-control" :class="{faded, 'has-input': inputText, centered}"
          :aria-describedby="`${id}-sr-status`"
          :value="displayValue" :placeholder="placeholder"
          @input="onInput($event.target.value)" @blur="updateText"
        >
        <InputClearButton
          v-if="clearButton"
          :show="!!value"
          right="6px"
          @click="clear"
        />
        <div v-else class="icon">
          <slot name="icon" />
        </div>
        <!-- <transition name="icon" mode="out-in">
          <span
            v-if="state" :key="icon"
            class="glyphicon form-control-feedback"
            :class="[icon ? `glyphicon-${icon}` : null]" aria-hidden="true"
          />
        </transition> -->
      </div>
      <span v-if="state" :id="`${id}-status-sr`" class="sr-only">{{ srState }}</span>
      <span v-if="addon || unit || usePercent" class="input-group-addon">
        <span v-if="usePercent">{{ $i18n.translateUnit('%') }}</span>
        <span v-if="unit && unit !== '%'">{{ $i18n.translateUnit(unit) }}</span> {{ addon }}
      </span>
      <slot name="inputGroup" />
    </div>
    <slot slot="label" name="label" />
    <slot v-if="$slots.description" slot="description" name="description" />
  </LabelAndMessage>
</template>

<script>
import {debounce} from 'lodash'

import RuleMixin from './RuleMixin'
import InputMixin from './input-base/InputMixin'
import FormPartMixin from 'src/vue/components/forms/FormPartMixin'
import ChangeDetectionMixin from 'src/vue/components/forms/ChangeDetectionMixin'
import makeResourceMixin from 'src/vue/mixins/make-resource-mixin'

import InputClearButton from '@components/InputClearButton'
import LabelAndMessage from './input-base/LabelAndMessage'

const resources = {
  SR_Common: 'Common.SR_Common',
  SR_ErrorMessages: 'Messages.Error.SR_ErrorMessages',
  SR_InfoMessages: 'Messages.Info.SR_InfoMessages'
}

let counter = 0

export default {
  components: {
    LabelAndMessage,
    InputClearButton
  },
  mixins: [
    RuleMixin,
    InputMixin,
    makeResourceMixin(resources),
    FormPartMixin,
    ChangeDetectionMixin
  ],
  props: {
    value: Number,
    format: String, // default, f2, f4
    placeholder: String, // placeholder has higher priority than default property of messages object
    integer: Boolean,
    addon: String,
    placement: String,
    description: String,
    readonly: Boolean,
    disabled: Boolean,
    name: String,
    hideDescription: Boolean,
    centered: Boolean,
    resetValue: Number,
    clearButton: Boolean,
    percent: Boolean,
    percentNotNormalized: Boolean,
    unit: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      id: `numeric-input-${counter++}`,
      convertedValue: null,
      truncatedValue: null,
      inputText: '',
      faded: false,
      dirty: false,

      onInput: debounce(this.onInputDebounced, 300)
    }
  },
  computed: {
    usePercent () {
      return this.percent || this.unit === '%' || this.percentNotNormalized
    },
    combinedDescription () {
      if (this.hideDescription) {
        return
      }
      const parts = [this.description, this.ruleDescription]
      return parts.filter(x => x)
    },
    overrideInputText () {
      return this.truncatedValue !== this.value && !isNaN(this.value)
    },
    formattedValue () {
      const newValue = this.usePercent && !this.percentNotNormalized ? this.value * 100 : this.value
      return this.value || this.value === 0
        ? this.$i18n.format(newValue, this.integer
          ? 'f0'
          : this.format)
        : ''
    },
    displayValue () {
      return this.overrideInputText
        ? this.formattedValue
        : this.inputText
    },
    fractionalDigits () {
      if (this.integer) {
        return 0
      }
      switch (this.format) {
      case 'f0': return 0
      case 'exact': return 20
      case 'f4': return 4
      case 'f2':
      default: return 2
      }
    },
    willBeTruncated () {
      return this.convertedValue !== this.truncatedValue
    },
    state () {
      // never validate without user entry
      if (!this.dirty) {
        return undefined
      }

      const states = {}

      if (this.required) {
        if (!this.value && this.value !== 0) { // 0 is falsy but allowed
          states.required = 'error'
        } else {
          states.required = 'success'
        }
      }

      if (this.value !== null && isNaN(this.value)) {
        states.isNumber = 'error'
      }

      if (this.willBeTruncated) {
        states.truncated = 'warning'
      }

      states.rules = this.ruleState

      // the 'or success' part coerces the undefined state that you get when no rules are available into a success state
      return this.combineStates(states) || 'success'
    },
    message () {
      const key = this.state || 'default'

      const internalHighPriority = {}
      const internalLowPriority = {
        error: this.SR_InfoMessages.RequiredField,
        warning: this.SR_Common.Truncate + ' ' + this.truncatedValue
      }

      if (isNaN(this.value)) {
        internalHighPriority.error = this.integer
          ? this.SR_ErrorMessages.OnlyIntegers
          : this.SR_ErrorMessages.OnlyFloatingNumbers
      }

      const prioritizedMessages = Object.assign(internalLowPriority, this.ruleMessages, internalHighPriority)
      return prioritizedMessages[key]
    }
  },
  methods: {
    updateText () {
      if (!this.$refs.input) return
      // TODO maybe only fade if new formatted text differs from this.inputText
      const convertedInput = this.convert(this.$refs.input.value)
      this.$emit('input', convertedInput)
      this.$emit('blur', convertedInput)

      this.faded = true
      setTimeout(() => {
        this.convertedValue = null
        this.truncatedValue = null
        this.faded = false
      }, 250)
    },
    convert (value) {
      this.inputText = value

      if (value === '') {
        this.convertedValue = null
        this.truncatedValue = null
      } else {
        this.convertedValue = this.usePercent && !this.percentNotNormalized
          ? (this.$i18n.parse(value, 'number') / 100)
          : this.$i18n.parse(value, 'number')
        this.truncatedValue = parseFloat(this.convertedValue.toFixed(this.fractionalDigits))
      }

      return this.truncatedValue
    },
    onInputDebounced (value) {
      this.dirty = true
      this.$emit('input', this.convert(value))
    },
    clear () {
      this.inputText = ''
      this.$emit('input', null)
      this.$refs.input.focus()
    }
  },
  watch: {
    value () {
      if (document.activeElement === this.$refs.input) return

      this.inputText = this.formattedValue
    }
  }
}
</script>

<style lang="scss" scoped>
/* additional used classes may come from bootstrap */

@import '~./input-base/input.scss';

input {
  transition: color 0.25s;
}

input.faded {
  color: white;
}

input::placeholder {
  transition: opacity 0.35s ease-in-out;
}

input.has-input::placeholder {
  opacity: 0;
}

input.centered {
  text-align: center;
}

// workaround for glyphicon removal
.has-feedback .form-control {
  padding-right: 12px;
}

.input-wrapper {
  position: relative;
}

.icon {
  position: absolute;
  width: 100%;
  top: 0;
  transform: translateY(50%);
  z-index: 2;

  font-size: 0.8em;
  padding-right: 0.4em;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  pointer-events: none;
}
</style>
