<template lang="html">
  <transition
    name="expand"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  data () {
    return {
      originalHeight: null
    }
  },
  methods: {
    enter (element) {
      this.originalHeight = element.style.height

      const width = getComputedStyle(element).width

      element.style.width = width
      element.style.position = 'absolute'
      element.style.visibility = 'hidden'
      element.style.height = 'auto'

      const height = getComputedStyle(element).height

      element.style.width = null
      element.style.position = null
      element.style.visibility = null
      element.style.height = 0

      requestAnimationFrame(() => {
        element.style.height = height
      })
    },
    afterEnter (element) {
      element.style.height = 'auto'
      this.$emit('shown')
    },
    leave (element) {
      const height = getComputedStyle(element).height

      element.style.height = height

      requestAnimationFrame(() => {
        element.style.height = 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.expand-enter-active,
.expand-leave-active {
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  transition-property: opacity, height;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
  opacity: 0;
}
</style>
