<template lang="html">
  <div class="search-bar">
    <div class="input-group">
      <span id="search-input-icon" class="input-group-addon">
        <span class="glyphicon glyphicon-search" aria-hidden="true" />
      </span>
      <input
        ref="input"
        type="text" class="form-control"
        :placeholder="SR_Common.Searching"
        :value="localValue"
        @input="onInput"
        @focus="hasFocus = true"
        @blur="onFocusLoss"
      >

      <InputClearButton :show="!!value" right="6px" @click="clear" />
    </div>
  </div>
</template>

<script>
import {debounce} from 'lodash'

import {mapResources} from '@helpers/vuex'

import InputClearButton from '@components/InputClearButton'

export default {
  components: {
    InputClearButton
  },
  props: {
    value: String,
    debounced: Boolean
  },
  data () {
    return {
      hasFocus: false,
      localValue: ''
    }
  },
  computed: {
    ...mapResources([
      'Common.SR_Common'
    ])
  },
  methods: {
    onFocusLoss () {
      this.hasFocus = false
      this.$emit('input', this.localValue)
      this.localValue = this.value
    },
    onInput (event) {
      this.localValue = event.target.value

      if (this.debounced) {
        this.emitDebounced()
      } else {
        this.emit()
      }
    },
    emitDebounced: debounce(function (event) {
      this.emit(event)
    }, 300),
    emit () {
      this.$emit('input', this.localValue)
    },
    clear () {
      this.localValue = ''
      this.emit()
      this.$refs.input.focus()
    }
  },
  watch: {
    value (value) {
      if (!this.hasFocus) {
        this.localValue = value
      }
    }
  },
  created () {
    this.localValue = this.value
  }
}
</script>

<style lang="scss" scoped>
</style>
