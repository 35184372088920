<template lang="html">
  <div class="mode-preview">
    <div class="mode-preview-panel" :class="{clickable: value, selected: value === name}" @click="onPanelClick">
      <div class="header">
        <h3>
          <FontAwesomeIcon :icon="icon" size="sm" />
          <IxRes>automaticZoneMaps.headers.{{ name }}</IxRes>
          <span v-if="comingSoon" class="coming-soon">
            <span>KOMMT</span>
            <span>BALD</span>
          </span>
        </h3>
      </div>

      <CollapseTransition>
        <div v-show="!value || showDetails" class="content">
          <div v-for="i in highlightCount" :key="i" class="highlight-row">
            <div class="highlight-icon">
              <FontAwesomeIcon :icon="icons.highlight" />
            </div>
            <h4>
              <IxRes>automaticZoneMaps.highlights.{{ name }}_{{ i }}</IxRes>
            </h4>
            <div class="description">
              <IxRes>automaticZoneMaps.highlights.{{ name }}_{{ i }}_description</IxRes>
            </div>
          </div>

          <div v-if="!value">
            <IxButton large @click="$emit('input', name)">
              <IxRes>automaticZoneMaps.buttons.{{ name }}_select</IxRes>
            </IxButton>
          </div>
        </div>
      </CollapseTransition>
    </div>

    <a v-if="value" href="#" @click.prevent="$emit('update:showDetails', !showDetails)">
      <IxRes v-if="!showDetails">automaticZoneMaps.buttons.showDetails</IxRes>
      <IxRes v-else>automaticZoneMaps.buttons.hideDetails</IxRes>
    </a>
  </div>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {faCheck} from '@fortawesome/free-solid-svg-icons'

import CollapseTransition from '@components/transitions/CollapseTransition'
import IxButton from '@components/IxButton'

export default {
  components: {
    FontAwesomeIcon,
    CollapseTransition,
    IxButton
  },
  props: {
    value: {
      type: String
    },
    name: {
      type: String,
      required: true
    },
    icon: {
      type: Object
    },
    highlightCount: {
      type: Number,
      default: 4
    },
    showDetails: Boolean,
    comingSoon: Boolean
  },
  computed: {
    icons () {
      return {
        highlight: faCheck
      }
    }
  },
  methods: {
    onPanelClick () {
      if (this.value) {
        this.$emit('input', this.name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$default: rgb(157, 157, 157);
$selected: rgb(39, 120, 227);

.mode-preview {
  display: flex;
  flex-direction: column;

  > a {
    margin-left: 1em;
  }

  margin-bottom: 1.5em;
}

.mode-preview-panel {
  margin: 0.5em;
  border: 1px solid $default;

  &.clickable {
    cursor: pointer;
  }

  .header, .content {
    padding: 0.5em;
  }

  .header {
    background-color: lighten($default, 30%);

    h3 {
      display: flex;
      align-items: center;
      margin: 0;

      > :first-child {
        margin-right: 0.5em;
      }
    }

    .coming-soon {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      padding: 0.3em;
      margin: -0.25em;
      margin-left: auto;

      font-size: 0.7em;
      font-weight: bold;
      line-height: 0.7em;

      background-color: #3ba941;
      background-color: #31708f;
      color: white;

      border: 1px solid white;
      border-radius: 0.3em;
    }
  }

  &.selected {
    border-color: $selected;

    .header {
      background-color: lighten($selected, 20%);
    }

    .content {
      background-color: lighten($default, 30%);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
  }

  .highlight-row {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-start;
    justify-items: flex-start;
    margin-bottom: 1em;

    .highlight-icon {
      margin-right: 0.5em;
      color: green;
    }

    h4 {
      margin-top: 0;
      font-weight: bold;
    }

    .description {
      grid-column-start: 2;
    }
  }
}
</style>
