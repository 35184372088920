<template lang="html">
  <div class="automatic-zone-maps">
    <h3>
      <IxRes>automaticZoneMaps.headers.automaticZoneMaps</IxRes>
    </h3>

    <div class="mode-selection">
      <ModePreview
        v-for="name in ['auto', 'premium']" :key="name"
        v-model="mode" :name="name"
        :show-details.sync="showModeDetails"
        :icon="icons[name]"
      />
    </div>

    <HelpBox v-if="!$route.params.mode">
      <IxRes>automaticZoneMaps.hints.modes</IxRes>
    </HelpBox>
    <template v-else>
      <p>
        <IxRes>automaticZoneMaps.hints.cultivations</IxRes>
      </p>

      <HelpBox>
        <IxRes>automaticZoneMaps.hints.moreInfoForBetterResult</IxRes>
      </HelpBox>
      <HelpBox type="warning">
        <IxRes>automaticZoneMaps.hints.moreInfoForBetterResult2</IxRes>
      </HelpBox>

      <div class="details-overview">
        <CultivationList />
        <AdditionalInfos v-if="mode === 'premium'" />
        <div v-else />
      </div>

      <MapPaymentTermsCheckbox v-if="mode === 'premium'" v-model="termsAccepted" is-mzk />

      <div>
        <HelpBox v-if="relevantCultivations.length && !supportedCultivations.length" type="warning">
          <IxRes>automaticZoneMaps.hints.noSupportedCultivations</IxRes>
        </HelpBox>

        <template v-if="mode === 'auto'">
          <AdvancedSettings v-if="$can('Create.Frs.AutomaticZoneMap.WithAdvancedSettings')" />

          <IxButton
            large
            :disabled="(supportedCultivations.length === 0) || (useRedBorder && redBorderError)"
            @click="maybeCreate"
          >
            <IxRes>automaticZoneMaps.buttons.calculateNow</IxRes>
          </IxButton>
        </template>
        <IxButton
          v-else
          large submit
          :disabled="!(supportedCultivations.length > 0 && termsAccepted)"
          @click="maybeCreate"
        >
          <IxRes>automaticZoneMaps.buttons.orderNow</IxRes>
        </IxButton>
      </div>

      <HelpBox class="help-box">
        <IxRes v-if="mode === 'auto'">automaticZoneMaps.hints.mapUsage</IxRes>
        <IxRes v-else>automaticZoneMaps.hints.mapUsagePlus</IxRes>
      </HelpBox>

      <CultivationsMissingModal
        v-if="showCultivationsMissingModal"
        v-model="showCultivationsMissingModal"
        @confirm="create"
        @cancel="showCultivationsMissingModal = false"
      />
      <FrsLoadingIndicator :loading="loading" />
    </template>
  </div>
</template>

<script>
import {faStar, faBolt} from '@fortawesome/free-solid-svg-icons'
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'

import {notifications} from 'src/js/infrastructure'
import {mapFormFields} from '@helpers/vuex'

import MapPaymentTermsCheckbox from '@components/MapPaymentTermsCheckbox'
import HelpBox from '@components/help/HelpBox'
import IxButton from '@components/IxButton'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'

import CultivationsMissingModal from './CultivationsMissingModal'
import ModePreview from './ModePreview'
import CultivationList from './CultivationList'
import AdditionalInfos from './AdditionalInfos'
import AdvancedSettings from './AdvancedSettings'

import PermissionMixin from '@mixins/PermissionMixin'

export default {
  components: {
    ModePreview,
    CultivationList,
    IxButton,
    HelpBox,
    CultivationsMissingModal,
    FrsLoadingIndicator,
    MapPaymentTermsCheckbox,
    AdditionalInfos,
    AdvancedSettings
  },
  mixins: [
    PermissionMixin
  ],
  data () {
    return {
      showCultivationsMissingModal: false,
      loading: false,
      termsAccepted: false,
      redBorderError: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/automaticZoneMaps', [
      'selectedQuickCultivations',
      'mode',
      'useRedBorder'
    ]),
    ...mapFormFields('fieldRecordSystem/automaticZoneMaps', [
      'showModeDetails',
      'automaticModeUseLandsat',
      'cloudCoverageThreshold'
    ]),
    ...mapGetters('fieldRecordSystem/automaticZoneMaps', [
      'yearsWithoutCultivation',
      'relevantCultivations',
      'supportedCultivations',
      'quickCultivations'
    ]),
    icons () {
      return {
        auto: faBolt,
        premium: faStar
      }
    },
    mode: {
      get () {
        return this.$route.params.mode
      },
      set (mode) {
        if (this.$route.params.mode === mode) return

        this.setMode(mode)

        this.$router.push({name: this.$route.name, params: {...this.$route.params, mode}})
      }
    }
  },
  methods: {
    ...mapMutations('fieldRecordSystem/automaticZoneMaps', [
      'setMode',
      'reset'
    ]),
    ...mapActions('fieldRecordSystem/automaticZoneMaps', [
      'startCreation',
      'createQuickCultivations'
    ]),
    maybeCreate () {
      if (this.yearsWithoutCultivation.length || this.quickCultivations.length) {
        this.showCultivationsMissingModal = true
      } else {
        this.create()
      }
    },
    async create () {
      this.showCultivationsMissingModal = false
      this.loading = true
      try {
        await this.startCreation()
        if (this.mode === 'premium') {
          notifications.success(this.$i18n.translate('automaticZoneMaps.notifications.mzkOrdered'))
        } else {
          notifications.success(this.$i18n.translate('automaticZoneMaps.notifications.creationStarted'))
        }
        this.loading = false
        this.$router.push({name: 'creatingAutomaticZoneMap'})

        if (this.selectedQuickCultivations.length > 0) {
          this.createQuickCultivations()
          .then(() => {
            notifications.success(this.$i18n.translate('automaticZoneMaps.notifications.success.cultivationCreation'))
          })
          .catch(() => {
            notifications.error(this.$i18n.translate('automaticZoneMaps.notifications.error.cultivationCreation'))
          })
        }
        this.reset()
      } catch (error) {
        console.error(error)
        this.loading = false
        notifications.success(this.$i18n.translate('automaticZoneMaps.notifications.creationError', error))
      }
    }
  },
  created () {
    this.setMode(this.$route.params.mode || null)
  }
}
</script>

<style lang="scss" scoped>
.automatic-zone-maps {
  display: flex;
  flex-direction: column;
  padding: 1em;

  flex: 1;
  overflow-y: auto;
  min-height: 0;

  > h3 {
    margin-top: 0;
  }

  > .mode-selection, .details-overview {
    display: flex;

    > * {
      flex: 1;
    }
  }
  .cultivations {
    display: flex;
  }

  .help-box {
    margin-top: 5px;
  }
}
</style>
