import { render, staticRenderFns } from "./CollapsibleSection.vue?vue&type=template&id=329a5962&scoped=true&lang=html&"
import script from "./CollapsibleSection.vue?vue&type=script&lang=js&"
export * from "./CollapsibleSection.vue?vue&type=script&lang=js&"
import style0 from "./CollapsibleSection.vue?vue&type=style&index=0&id=329a5962&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "329a5962",
  null
  
)

export default component.exports